import {Provider} from 'jotai'
import {AppProps} from 'next/app'
import PlausibleProvider from 'next-plausible'
import {MapProvider} from 'react-map-gl'

import {MessageContext} from '@/hooks/use-messages'

import 'mapbox-gl/dist/mapbox-gl.css'
import '@/style.css'

export default function TauiApp({Component, pageProps}: AppProps) {
  return (
    <PlausibleProvider domain='betterbus.taui.conveyal.com' trackOutboundLinks>
      <MessageContext.Provider value={pageProps.messages}>
        <Provider>
          <MapProvider>
            <Component {...pageProps} />
          </MapProvider>
        </Provider>
      </MessageContext.Provider>
    </PlausibleProvider>
  )
}
